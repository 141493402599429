




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateExercise } from '@/store/crud/actions';
import ExerciseForm from '@/components/forms/ExerciseForm.vue';
import { ICreateExercise } from '@/interfaces/exercises';


@Component({ components: { ExerciseForm } })
export default class AdminExercise extends AppComponent {
  @Prop({ type: String, required: false }) public exerciseId: string;

  public exerciseUpdated() {
    this.$router.push('/main/content/exercises');
  }

  public async exerciseCreated(exercisePayload: ICreateExercise) {
    if (exercisePayload && Object.keys(exercisePayload).length > 0) {
      const result = await dispatchCreateExercise(this.$store, exercisePayload);
      if (result) {
          this.$router.push('/main/content/exercises');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/exercises');
  }
}
